import React from 'react';

import { container, loader, errorBox } from './fetch-status-info.module.scss';

import Loader from '../atoms/loader';
import BackLink, { IBackLinkProps } from '../hoc/back-link';

interface IFetchStatusInfoProps {
    className?: string;
    isLoading?: boolean;
    isError?: boolean;
    error?: string;
    backLinkProps?: Omit<IBackLinkProps, 'className'>;
}

const FetchStatusInfo: React.FC<IFetchStatusInfoProps> = ({
    className = '',
    isError,
    error,
    isLoading,
    backLinkProps,
}) => {
    return (
        <div className={`${container} ${className}`}>
            {isLoading && <Loader className={loader} />}
            {isError && (
                <div className={errorBox}>
                    <p>{error}</p>
                    <BackLink {...backLinkProps} />
                </div>
            )}
        </div>
    );
};

export default FetchStatusInfo;
