import alterpressApi from './base';

import { IClientMailTemplate } from '../../models/client-mail-template.model';
import { IClientMailTemplateValues } from '../../formik/client-mail-template.form';

export const clientMailTemplateApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_MAIL_TEMPLATE'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getAllClientMailTemplates: builder.query<IClientMailTemplate[], void>({
                query: () => `client-mail-templates/all`,
                providesTags: ['CLIENT_MAIL_TEMPLATE'],
            }),
            getClientMailTemplate: builder.query<
                IClientMailTemplate,
                number | string | undefined | null
            >({
                query: (templateId) => `client-mail-templates/${templateId}`,
                providesTags: ['CLIENT_MAIL_TEMPLATE'],
            }),
            createClientMailTemplate: builder.mutation<
                IClientMailTemplate,
                IClientMailTemplateValues
            >({
                query: (data) => ({
                    url: `client-mail-templates`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_MAIL_TEMPLATE'],
            }),
            updateClientMailTemplate: builder.mutation<
                IClientMailTemplate,
                { templateId: number | string; data: IClientMailTemplateValues }
            >({
                query: ({ data, templateId }) => ({
                    url: `client-mail-templates/${templateId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_MAIL_TEMPLATE'],
            }),
            deleteClientMailTemplate: builder.mutation<void, number | string>({
                query: (templateId) => ({
                    url: `client-mail-templates/${templateId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_MAIL_TEMPLATE'],
            }),
        }),
    });

export const {
    useGetAllClientMailTemplatesQuery,
    useGetClientMailTemplateQuery,
    useCreateClientMailTemplateMutation,
    useUpdateClientMailTemplateMutation,
    useDeleteClientMailTemplateMutation,
} = clientMailTemplateApi;
