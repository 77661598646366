import React from 'react';
import { Link } from 'gatsby';

import useTranslations from '../../hooks/use-translations';
import { useBackLink } from '../../hooks/use-back-link';

import Button, { TButtonProps } from '../atoms/button';

export interface IBackLinkProps {
    className?: string;
    fallback?: string;
    children?: React.ReactNode;
    isButton?: boolean;
    buttonProps?: Omit<TButtonProps, 'as' | 'to' | 'className'>;
}

const BackLink: React.FC<IBackLinkProps> = ({
    className = '',
    children,
    fallback,
    buttonProps,
    isButton = true,
}) => {
    const t = useTranslations('BackLink');
    const prevPathname = useBackLink(fallback);

    if (isButton) {
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Button stylePreset="secondary" {...buttonProps} as="link" to={prevPathname}>
                {children || t.goBack}
            </Button>
        );
    }

    return (
        <Link className={className} to={prevPathname}>
            {children || t.goBack}
        </Link>
    );
};

export default BackLink;
