import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useGetClientMailTemplateQuery,
    useCreateClientMailTemplateMutation,
    useDeleteClientMailTemplateMutation,
    useUpdateClientMailTemplateMutation,
    useGetAllClientMailTemplatesQuery,
} from '../redux/api/client-mail-template';

export type TUseClientMailTemplateQueries = 'single' | 'all';

interface IUseClientMailTemplateConfig {
    queries?: TUseClientMailTemplateQueries[];
    templateId?: number | string | null;
}

export const useClientMailTemplate = ({
    queries = [],
    templateId,
}: IUseClientMailTemplateConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipTemplateQuery = !queries.includes('single') || !templateId;
    const allTemplatesQuery = !queries.includes('all');

    const getTemplateQuery = useGetClientMailTemplateQuery(templateId, { skip: skipTemplateQuery });
    const getAllTemplatesQuery = useGetAllClientMailTemplatesQuery(undefined, {
        skip: allTemplatesQuery,
    });
    const [create, createMutation] = useCreateClientMailTemplateMutation();
    const [update, updateMutation] = useUpdateClientMailTemplateMutation();
    const [deleteTemplate, deleteMutation] = useDeleteClientMailTemplateMutation();

    const allErrors = useMemo(() => {
        return getRtkQueryErrors(getAllTemplatesQuery.error, t.errors);
    }, [getAllTemplatesQuery.error, t.errors]);
    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getTemplateQuery.error, t.errors);
    }, [getTemplateQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createMutation.error, t.errors);
    }, [createMutation.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);

    return {
        all: {
            data: getAllTemplatesQuery.data,
            isFetching: getAllTemplatesQuery.isFetching,
            isLoading: getAllTemplatesQuery.isLoading,
            isSuccess: getAllTemplatesQuery.isSuccess,
            isError: getAllTemplatesQuery.isError,
            errors: allErrors,
        },
        single: {
            data: getTemplateQuery.data,
            isFetching: getTemplateQuery.isFetching,
            isLoading: getTemplateQuery.isLoading,
            isSuccess: getTemplateQuery.isSuccess,
            isError: getTemplateQuery.isError,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isUnauthorized: getTemplateQuery.error?.status === 403,
            errors: singleErrors,
        },
        create: {
            fetch: create,
            data: createMutation.data,
            isLoading: createMutation.isLoading,
            isSuccess: createMutation.isSuccess,
            isError: createMutation.isError,
            errors: createErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteTemplate,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
    };
};
