import * as Yup from 'yup';
import { getAllMediaByRelation } from '@alterpage/gatsby-plugin-image';

import { relations } from '../config/relations';
import IFormField from '../models/form-field.model';
import { IFileBase64 } from '../models/file-base-64.model';
import { IClientMailTemplate } from '../models/client-mail-template.model';
import { IOption } from '../models/option.model';
import useTranslations from '../hooks/use-translations';

const MAX_CONTENT_LENGTH = 1500;
const CONTENT_ROWS = 7;

export interface IClientMailTemplateValues {
    language: string;
    name: string;
    subject: string;
    content: string;
    attachments: IFileBase64[];
}

export function getClientMailTemplateInitialValues(
    template?: IClientMailTemplate
): IClientMailTemplateValues {
    const attachments = template && getAllMediaByRelation(template.media, relations.attachment);

    return {
        language: template?.language || '',
        name: template?.name || '',
        subject: template?.subject || '',
        content: template?.content || '',
        attachments:
            (attachments &&
                attachments.map((file) => {
                    return {
                        name: file.name,
                        content: null,
                        url: file.url,
                        mimeType: file.type,
                        fileId: file.fileId,
                    };
                })) ||
            [],
    };
}

export function getClientMailTemplateValidationSchema(
    t: ReturnType<typeof useTranslations<'ClientMailTemplateForm'>>
) {
    return Yup.object({
        language: Yup.string().required(t.lang.error.required),
        name: Yup.string().required(t.name.error.required),
        subject: Yup.string().required(t.subject.error.required),
        content: Yup.string()
            .max(MAX_CONTENT_LENGTH, `${t.content.error.max}${MAX_CONTENT_LENGTH}`)
            .required(t.content.error.required),
    });
}

export function getClientMailTemplateFields(
    t: ReturnType<typeof useTranslations<'ClientMailTemplateForm'>>,
    languagesOptions: IOption[]
): IFormField[] {
    return [
        {
            name: 'language',
            type: 'select',
            placeholder: t.lang.placeholder,
            hint: t.lang.hint,
            isRequired: true,
            isClearable: false,
            options: languagesOptions,
        },
        {
            name: 'name',
            type: 'text',
            placeholder: t.name.placeholder,
            isRequired: true,
        },
        {
            name: 'subject',
            type: 'text',
            placeholder: t.subject.placeholder,
            isRequired: true,
        },
        {
            name: 'content',
            type: 'textarea',
            placeholder: t.content.placeholder,
            isRequired: true,
            maxLength: MAX_CONTENT_LENGTH,
            rows: CONTENT_ROWS,
        },
        {
            name: 'attachments',
            type: 'input-file-array',
            addButtonText: t.attachments.add,
            addButtonMoreText: t.attachments.addMore,
            maxFileCount: 2,
        },
    ];
}
